import validate from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.13.2_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1.13_e_r6hklrxu64ggcatd3lzcgdnowm/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45rules_45global from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/middleware/redirect-rules.global.ts";
import _01_45auth_45required_45global from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/middleware/auth-required.global.ts";
import manifest_45route_45rule from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.13.2_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1.13_e_r6hklrxu64ggcatd3lzcgdnowm/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45rules_45global,
  _01_45auth_45required_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "is-content-admin": () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/middleware/is-content-admin.ts")
}