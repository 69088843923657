import { default as edit_45typeRinv3yX5OOMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/edit-type.vue?macro=true";
import { default as _91id_930IyZgRztSsMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/engine/[id].vue?macro=true";
import { default as create4uyO9lhl9PMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/engine/create.vue?macro=true";
import { default as index6yOAxAz1NTMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/engine/index.vue?macro=true";
import { default as engine4U0TG2xtJBMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/engine.vue?macro=true";
import { default as index1bqpNo1XUAMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/index.vue?macro=true";
import { default as enginesvdFgHWVUzbMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy-editor/[id]/engines.vue?macro=true";
import { default as indexYGsUwOfinFMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy-editor/[id]/index.vue?macro=true";
import { default as indexYP32lJIy1ZMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy-editor/[id]/reference-document/index.vue?macro=true";
import { default as _91id_93ez4u4pIaWEMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy-editor/[id].vue?macro=true";
import { default as index729BbakXKDMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy-editor/index.vue?macro=true";
import { default as policy_45editorOKqyrJpQFUMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy-editor.vue?macro=true";
import { default as indexjUnCcqhhJCMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy/[id]/index.vue?macro=true";
import { default as editHrgOKeqoU5Meta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy/[id]/reference-document/[docId]/edit.vue?macro=true";
import { default as addNumjeOsjQaMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy/[id]/reference-document/add.vue?macro=true";
import { default as createhyYqWGGbBhMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy/create.vue?macro=true";
import { default as indexFfGnUjTNhoMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy/index.vue?macro=true";
import { default as _91id_93RPMHLTZTTrMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/provider/[id].vue?macro=true";
import { default as create6ubKN5nGFmMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/provider/create.vue?macro=true";
import { default as indexFG6eaOpZ5xMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/provider/index.vue?macro=true";
import { default as providerhVjAmTn6aDMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/provider.vue?macro=true";
import { default as region9ooyKCoElaMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/region.vue?macro=true";
import { default as source_45documentMRLROgQsJnMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/source-document.vue?macro=true";
import { default as source_45type_45level_451ClB0ZOYMB5Meta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/source-type-level-1.vue?macro=true";
import { default as source_45type_45level_452melyIBBg7WMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/source-type-level-2.vue?macro=true";
import { default as _91id_934Z7YAhpfp0Meta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/topic/[id].vue?macro=true";
import { default as createADukAaBuwjMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/topic/create.vue?macro=true";
import { default as indexYtFRziNZt9Meta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/topic/index.vue?macro=true";
import { default as topicAje3aTCMeQMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/topic.vue?macro=true";
import { default as auth_45callback852wOlvwczMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-callback.vue?macro=true";
import { default as auth_45loginj4HtcCjkEiMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-login.vue?macro=true";
import { default as auth_45statusPp8tL2HSytMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-status.vue?macro=true";
export default [
  {
    name: "edit-type",
    path: "/edit-type",
    meta: edit_45typeRinv3yX5OOMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/edit-type.vue")
  },
  {
    name: engine4U0TG2xtJBMeta?.name,
    path: "/engine",
    meta: engine4U0TG2xtJBMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/engine.vue"),
    children: [
  {
    name: "engine-id",
    path: ":id()",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/engine/[id].vue")
  },
  {
    name: "engine-create",
    path: "create",
    meta: create4uyO9lhl9PMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/engine/create.vue")
  },
  {
    name: "engine",
    path: "",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/engine/index.vue")
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/index.vue")
  },
  {
    name: policy_45editorOKqyrJpQFUMeta?.name,
    path: "/policy-editor",
    meta: policy_45editorOKqyrJpQFUMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy-editor.vue"),
    children: [
  {
    name: _91id_93ez4u4pIaWEMeta?.name,
    path: ":id()",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy-editor/[id].vue"),
    children: [
  {
    name: "policy-editor-id-engines",
    path: "engines",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy-editor/[id]/engines.vue")
  },
  {
    name: "policy-editor-id",
    path: "",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy-editor/[id]/index.vue")
  },
  {
    name: "policy-editor-id-reference-document",
    path: "reference-document",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy-editor/[id]/reference-document/index.vue")
  }
]
  },
  {
    name: "policy-editor",
    path: "",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy-editor/index.vue")
  }
]
  },
  {
    name: "policy-id",
    path: "/policy/:id()",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy/[id]/index.vue")
  },
  {
    name: "policy-id-reference-document-docId-edit",
    path: "/policy/:id()/reference-document/:docId()/edit",
    meta: editHrgOKeqoU5Meta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy/[id]/reference-document/[docId]/edit.vue")
  },
  {
    name: "policy-id-reference-document-add",
    path: "/policy/:id()/reference-document/add",
    meta: addNumjeOsjQaMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy/[id]/reference-document/add.vue")
  },
  {
    name: "policy-create",
    path: "/policy/create",
    meta: createhyYqWGGbBhMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy/create.vue")
  },
  {
    name: "policy",
    path: "/policy",
    meta: indexFfGnUjTNhoMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/policy/index.vue")
  },
  {
    name: providerhVjAmTn6aDMeta?.name,
    path: "/provider",
    meta: providerhVjAmTn6aDMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/provider.vue"),
    children: [
  {
    name: "provider-id",
    path: ":id()",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/provider/[id].vue")
  },
  {
    name: "provider-create",
    path: "create",
    meta: create6ubKN5nGFmMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/provider/create.vue")
  },
  {
    name: "provider",
    path: "",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/provider/index.vue")
  }
]
  },
  {
    name: "region",
    path: "/region",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/region.vue")
  },
  {
    name: "source-document",
    path: "/source-document",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/source-document.vue")
  },
  {
    name: "source-type-level-1",
    path: "/source-type-level-1",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/source-type-level-1.vue")
  },
  {
    name: "source-type-level-2",
    path: "/source-type-level-2",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/source-type-level-2.vue")
  },
  {
    name: topicAje3aTCMeQMeta?.name,
    path: "/topic",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/topic.vue"),
    children: [
  {
    name: "topic-id",
    path: ":id()",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/topic/[id].vue")
  },
  {
    name: "topic-create",
    path: "create",
    meta: createADukAaBuwjMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/topic/create.vue")
  },
  {
    name: "topic",
    path: "",
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/content/pages/topic/index.vue")
  }
]
  },
  {
    name: "auth-callback",
    path: "/auth/callback",
    meta: auth_45callback852wOlvwczMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-callback.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: auth_45loginj4HtcCjkEiMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-login.vue")
  },
  {
    name: "auth-status",
    path: "/auth/status",
    meta: auth_45statusPp8tL2HSytMeta || {},
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-status.vue")
  }
]