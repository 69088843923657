import revive_payload_client_iUFN7YklPJ from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.13.2_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1.13_e_r6hklrxu64ggcatd3lzcgdnowm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_hi94jOmHxy from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.13.2_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1.13_e_r6hklrxu64ggcatd3lzcgdnowm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_2OvkW4coJz from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.13.2_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1.13_e_r6hklrxu64ggcatd3lzcgdnowm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_ZHLqU4plbp from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.13.2_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1.13_e_r6hklrxu64ggcatd3lzcgdnowm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_yQsBUN3crL from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.13.2_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1.13_e_r6hklrxu64ggcatd3lzcgdnowm/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_jsMdfttrke from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.13.2_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1.13_e_r6hklrxu64ggcatd3lzcgdnowm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_vzjimSHn3E from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.13.2_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1.13_e_r6hklrxu64ggcatd3lzcgdnowm/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_Yf9zzYJA7C from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.13.2_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1.13_e_r6hklrxu64ggcatd3lzcgdnowm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_ASGcABqDKm from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/.nuxt/components.plugin.mjs";
import prefetch_client_HT9llhsFn1 from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.13.2_@biomejs+biome@1.8.3_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1.13_e_r6hklrxu64ggcatd3lzcgdnowm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/.nuxt/pwa-icons-plugin.ts";
import pwa_client_EyQNFgbyLJ from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/@vite-pwa+nuxt@0.10.5_@vite-pwa+assets-generator@0.0.11_magicast@0.3.5_rollup@4.24.0_vite@5.4_aulpgk4wxoddtuxytn5cisuohq/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import unocss_MzCDxu9LMj from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/.nuxt/unocss.mjs";
import pendo_hkesixFq4o from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-pendo/src/runtime/pendo.ts";
import datadog_JKmUALlR6H from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-datadog/src/runtime/datadog.ts";
import plugin_526V8oWKGJ from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/plugin.ts";
import formkitPlugin_pZqjah0RUG from "/builds/rialtic/qa/argocd-releases/console-ui/apps/content/.nuxt/formkitPlugin.mjs";
import plugin_PXQeuxRKoc from "/builds/rialtic/qa/argocd-releases/console-ui/packages/ui/src/runtime/plugin.ts";
import plugin_cJj0AcbkNf from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.0_@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_typesc_2d3cjpsolqu2fc3cvefev35hoq/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
export default [
  revive_payload_client_iUFN7YklPJ,
  unhead_hi94jOmHxy,
  router_2OvkW4coJz,
  payload_client_ZHLqU4plbp,
  navigation_repaint_client_yQsBUN3crL,
  check_outdated_build_client_jsMdfttrke,
  view_transitions_client_vzjimSHn3E,
  chunk_reload_client_Yf9zzYJA7C,
  plugin_vue3_ASGcABqDKm,
  components_plugin_KR1HBZs4kY,
  prefetch_client_HT9llhsFn1,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_EyQNFgbyLJ,
  unocss_MzCDxu9LMj,
  pendo_hkesixFq4o,
  datadog_JKmUALlR6H,
  plugin_526V8oWKGJ,
  formkitPlugin_pZqjah0RUG,
  plugin_PXQeuxRKoc,
  plugin_cJj0AcbkNf
]